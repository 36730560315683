@import "../admin-style";
@import "../../../theme";
.admin-sidebar {
    border-right: 1px solid rgba(0,0,0,0.1);
    background-color: $admin-gray-color;
}

@media screen and (min-width: 768px){
    .admin-sidebar {
        min-height: 100vh;
    }
}

.admin-sidebar-menu {
    list-style: none;
    justify-content: center;
    display: flex;
}

.items-wrapper {
    align-self: center;
    flex-direction: column;
    display: inline-block;
}

.admin-sidebar-menu-item {
    text-decoration: none;
    padding: 0.5em 1em;
    color: white;
    white-space: nowrap;
}


//.admin-sidebar-menu-item:before {
//    content: "<br />";
//}

.admin-sidebar-menu-item-icon {

}

.admin-sidebar-menu-item-text {
    margin-left: 0.5em;
}


