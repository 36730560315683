$kazboek-header-color: #4d4d4d;
$kazboek-header-height: 140px;
$kazboek-header-height-small: 80px;
$kazboek-header-line-height: 10px;
$kazboek-header-line-color: #fecc33;

$kazboek-menu-font: 'Helvetica';
$kazboek-menu-font-size: 15pt;
$kazboek-menu-spacing: 38px;
$kazboek-menu-vertical-line-height: 30px;
$kazboek-menu-vertical-line-width: 1pt;
$kazboek-menu-color: white;

$kazboek-sub-menu-font: 'Helvetica Neue regular';
$kazboek-sub-menu-size: 20pt;
$kazboek-sub-menu-spacing: 34px;
$kazboek-sub-menu-vertical-line-height: 25px;
$kazboek-sub-menu-vertical-line-width: 0.5pt;

$kazboek-subject-bar-font: 'Helvetica Neue Regular';
$kazboek-subject-bar-font-size: 15pt;
$kazboek-subject-bar-font-color: #4d4d4d;

$kazboek-button-color: #004582;
