// theme.scss
@import "kazboek-style";

$theme-colors: (
        "kasboek": rgba(153, 200, 175, 0.42),
        "kasboek-debt": #c123a2,
        "kasboek-date-error": #ff999e,
        "kasboek-header": $kazboek-header-color,
        "primary": $kazboek-button-color,

  //Bootstrap override.

);

$grid-gutter-width: 15px;
$list-group-item-padding-x: 10px;

@import '../node_modules/bootstrap/scss/bootstrap';


@media (max-width: 768px){
  .kasboek-header{
    min-height: $kazboek-header-height-small !important;
  }
}

.kazboek-link {
  text-display: none;
  color: black;
}

.kasboek-header {
  border-bottom: $kazboek-header-line-height solid $kazboek-header-line-color;
  min-height: $kazboek-header-height;
  background-color: $kazboek-header-color;
  font-size: $kazboek-menu-font-size;
  font-family: $kazboek-menu-font;
}


.entry-popover {
  max-width: 500px;
}

.btn-group-amount-sign .btn.active::after {
  text-decoration: none;
  border-bottom: none;
}

.droppable {
  transition: color 0.1s;
  position: relative;
}

.droppable.row-dragging {
  color: transparent;
}

.row-dragging::before {
  position: absolute;
  top: -15%;
  width: 100%;
  height: 130%;
  color: black;
  background-color: #e2e2e2;
  content: "Bon uploaden";
  display: flex;
  justify-content: center;
  align-items: center;
}
