@import "../../kazboek-style.scss";

$admin-gray-color: rgba(0, 0, 0, 0.05);
$admin-gray-color-accent: lighten($admin-gray-color, 30%);
$admin-gray-color-hover: rgba(0,0,0,0.1);

.admin-title-text {
  margin-left: 0.5em;
}

$margin-height:  0.3em;
$margin-width: 0em;

.admin-list-header>* {
  margin: 0.3em 0.3em;
  padding: 0.5em 0.5em;
}

.admin-info-card {
  border-left: 3px solid $kazboek-button-color;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 0.5em 0.5em;
  margin: 0.3em 0.3em;
  background-color: $admin-gray-color-accent;
  width: 100%;
}

.admin-list-item {
  border-left: 3px solid $kazboek-button-color;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 0.5em 0.5em;
  margin: $margin-height $margin-width;
  background-color: $admin-gray-color-accent;
}

.admin-list-item:hover {
  transition: all 0.3s ease-in-out;
  background-color: $admin-gray-color-hover;
}

.admin-spaced-icon {
  margin: 0em 0.3em;
}

