

.even-tabs{
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0;
  padding: 0;
}

.even-tabs>a{
  flex: 1;
  text-align: center;
}
