

.currency {
  margin-left: auto;
}

.super-dangerous {
  border-left: 3px solid red;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  padding: 1em 1em;
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 0, 0, 0.07)
}

.super-dangerous-icon {
  color: red;
  width: 2em;
  height: 2em;
}

.super-dangerous-text {
  padding-left: 1em;
}
